import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requireAuth: true },
    component: Home,
    redirect: "/views/logo",
    children:[{
      path: '/views/logo',
      name: 'logo',
      component: () => import('../views/logo.vue')
    },{
      path: '/views/personal',
      name: 'personal',
      component: () => import('../views/personal.vue')
    },{
      path: '/menu/MenuList',
      name: 'MenuList',
      component: () => import('../views/menu/MenuList.vue')
    },
    {
      path: '/menu/MenuAdd',
      name: 'MenuAdd',
      component: () => import('../views/menu/MenuAdd.vue')
    },
    {
      path: '/menu/MenuEdit',
      name: 'MenuEdit',
      component: () => import('../views/menu/MenuEdit.vue')
    },
    {
      path: '/role/roleadd',
      name: 'roleadd',
      component: () => import('../views/role/roleadd.vue')
    },
    {
      path: '/role/RoleList',
      name: 'RoleList',
      component: () => import('../views/role/RoleList.vue')
    },
    {
      path: '/role/roleedit',
      name: 'roleedit',
      component: () => import('../views/role/roleedit.vue')
    },
    {
      path: '/role/rolebind',
      name: 'rolebind',
      component: () => import('../views/role/rolebind.vue')
    },
    {
      path: '/user/userlist',
      name: 'userlist',
      component: () => import('../views/user/userlist.vue')
    },
    {
      path: '/user/useradd',
      name: 'useradd',
      component: () => import('../views/user/useradd.vue')
    },
    {
      path: '/user/useredit',
      name: 'useredit',
      component: () => import('../views/user/useredit.vue')
    },
    {
      path: '/route/routelist',
      name: 'routelist',
      component: () => import('../views/route/routelist.vue')
    },
    {
      path: '/route/routeadd',
      name: 'routeadd',
      component: () => import('../views/route/routeadd.vue')
    },
    {
      path: '/route/routeedit',
      name: 'routeedit',
      component: () => import('../views/route/routeedit.vue')
    },
    {
      path: '/comment/commentlist',
      name: 'commentlist',
      component: () => import('../views/comment/commentlist.vue')
    },
    {
      path: '/comment/commentedit',
      name: 'commentedit',
      component: () => import('../views/comment/commentedit.vue')
    },
    {
      path: '/comment/commentexaminelist',
      name: 'commentexaminelist',
      component: () => import('../views/comment/commentexaminelist.vue')
    },
    {
      path: '/userexamine/portraitlist',
      name: 'portraitlist',
      component: () => import('../views/userexamine/portraitlist.vue')
    },
    {
      path: '/userexamine/nicknamelist',
      name: 'nicknamelist',
      component: () => import('../views/userexamine/nicknamelist.vue')
    },
    {
      path: '/userexamine/introductionlist',
      name: 'introductionlist',
      component: () => import('../views/userexamine/introductionlist.vue')
    },
    {
      path: '/label/articlelabellist',
      name: 'articlelabellist',
      component: () => import('../views/label/articlelabellist.vue')
    },
    {
      path: '/label/articlelabeladd',
      name: 'articlelabeladd',
      component: () => import('../views/label/articlelabeladd.vue')
    },
    {
      path: '/label/articlelabeledit',
      name: 'articlelabeledit',
      component: () => import('../views/label/articlelabeledit.vue')
    },
    {
      path: '/label/topiclist',
      name: 'topiclist',
      component: () => import('../views/label/topiclist.vue')
    },
    {
      path: '/label/topicadd',
      name: 'topicadd',
      component: () => import('../views/label/topicadd.vue')
    },
    {
      path: '/label/topicedit',
      name: 'topicedit',
      component: () => import('../views/label/topicedit.vue')
    },
    {
      path: '/label/articleadd',
      name: 'articleadd',
      component: () => import('../views/label/articleadd.vue')
    },
    {
      path: '/label/articlelist',
      name: 'articlelist',
      component: () => import('../views/label/articlelist.vue')
    },
    {
      path: '/label/articleedit',
      name: 'articleedit',
      component: () => import('../views/label/articleedit.vue')
    },
    {
      path: '/topic/edit',
      name: 'edit',
      component: () => import('../views/topic/edit.vue')
    },
    {
      path: '/topic/add',
      name: 'add',
      component: () => import('../views/topic/add.vue')
    },
    {
      path: '/topic/list',
      name: 'list',
      component: () => import('../views/topic/list.vue')
    },
    {
      path: '/article/publish',
      name: 'articlepublish',
      component: () => import('../views/article/publish.vue')
    },
    {
      path: '/article/list',
      name: 'articlelistv',
      component: () => import('../views/article/list.vue')
    },
    {
      path: '/article/list2',
      name: 'articlelistv2',
      component: () => import('../views/article/list2.vue')
    },
    {
      path: '/article/artedit',
      name: 'artedit',
      component: () => import('../views/article/artedit.vue')
    },
    {
      path: '/article/publishedit',
      name: 'publishedit',
      component: () => import('../views/article/publishedit.vue')
    },
    {
      path: '/article/publishadd',
      name: 'publishadd',
      component: () => import('../views/article/publishadd.vue')
    },
    {
      path: '/article/editarticlelabel',
      name: 'editarticlelabel',
      component: () => import('../views/article/editarticlelabel.vue')
    },
    {
      path: '/model/label',
      name: 'label',
      component: () => import('../views/model/label.vue')
    },
    {
      path: '/model/selectlabel',
      name: 'selectlabel',
      component: () => import('../views/model/selectlabel.vue')
    },
    {
      path: '/article/selectarticlelabel',
      name: 'selectarticlelabel',
      component: () => import('../views/article/selectarticlelabel.vue')
    },
    {
      path: '/userexamine/articlereview',
      name: 'articlereview',
      component: () => import('../views/userexamine/articlereview.vue')
    },
    {
      path: '/userexamine/articleexaminelist',
      name: 'articleexaminelist',
      component: () => import('../views/userexamine/articleexaminelist.vue')
    },
    {
      path: '/userexamine/articleviewedit',
      name: 'articleviewedit',
      component: () => import('../views/userexamine/articleviewedit.vue')
    },
    {
      path: '/feedback/feedbacklist',
      name: 'feedbacklist',
      component: () => import('../views/feedback/feedbacklist.vue')
    },
    {
      path: '/feedback/feedbacklist2',
      name: 'feedbacklist2',
      component: () => import('../views/feedback/feedbacklist.vue')
    },
    {
      path: '/feedback/feedbackedit',
      name: 'feedbackedit',
      component: () => import('../views/feedback/feedbackedit.vue')
    },
    {
      path: '/usermanagement/adduser',
      name: 'usermanagementadd',
      component: () => import('../views/usermanagement/adduser.vue')
    },
    {
      path: '/usermanagement/edituser',
      name: 'usermanagementedituser',
      component: () => import('../views/usermanagement/edituser.vue')
    },
    {
      path: '/usermanagement/usermanagementlist',
      name: 'usermanagementlist',
      component: () => import('../views/usermanagement/usermanagementlist.vue')
    },
    {
      path: '/usermanagement/usermanagementedit',
      name: 'usermanagementedit',
      component: () => import('../views/usermanagement/usermanagementedit.vue')
    },
    {
      path: '/usermanagement/authenticateduserlist',
      name: 'authenticateduserlist',
      component: () => import('../views/usermanagement/authenticateduserlist.vue')
    },
    {
      path: '/usermanagement/authenticateduseradd',
      name: 'authenticateduseradd',
      component: () => import('../views/usermanagement/authenticateduseradd.vue')
    },
    {
      path: '/usermanagement/authenticateduseredit',
      name: 'authenticateduseredit',
      component: () => import('../views/usermanagement/authenticateduseredit.vue')
    },
    {
      path: '/frontpage/frontpagelabellist',
      name: 'frontpagelabellist',
      component: () => import('../views/frontpage/frontpagelabellist.vue')
    },
    {
      path: '/frontpage/frontpagelabeladd',
      name: 'frontpagelabeladd',
      component: () => import('../views/frontpage/frontpagelabeladd.vue')
    },
    {
      path: '/frontpage/frontpagelabeledit',
      name: 'frontpagelabeledit',
      component: () => import('../views/frontpage/frontpagelabeledit.vue')
    },
    {
      path: '/interestpage/interestadd',
      name: 'interestadd',
      component: () => import('../views/interestpage/interestadd.vue')
    },
    {
      path: '/interestpage/interestedit',
      name: 'interestedit',
      component: () => import('../views/interestpage/interestedit.vue')
    },
    {
      path: '/interestpage/interestlist',
      name: 'interestlist',
      component: () => import('../views/interestpage/interestlist.vue')
    },
    {
      path: '/sensitive/sensitivelist',
      name: 'sensitivelist',
      component: () => import('../views/sensitive/sensitivelist.vue')
    },
    {
      path: '/sensitive/sensitivedetail',
      name: 'sensitivedetail',
      component: () => import('../views/sensitive/sensitivedetail.vue')
    },
    {
      path: '/headCategory/headCategory',
      name: 'headCategory',
      component: () => import('../views/headCategory/headCategory.vue')
    },
    {
      path: '/headCategory/headCategoryadd',
      name: 'headCategoryadd',
      component: () => import('../views/headCategory/headCategoryadd.vue')
    },
    {
      path: '/headCategory/headCategoryedit',
      name: 'headCategoryedit',
      component: () => import('../views/headCategory/headCategoryedit.vue'),
      props:true
    },
    {
      path: '/headCategory/headShop',
      name: 'headShop',
      component: () => import('../views/headCategory/headShop.vue')
    },
    {
      path: '/headCategory/headShopadd',
      name: 'headShopadd',
      component: () => import('../views/headCategory/headShopadd.vue')
    },
    {
      path: '/headCategory/headShopedit',
      name: 'headShopedit',
      component: () => import('../views/headCategory/headShopedit.vue'),
      props:true
    },
    {
      path: '/headCategory/headOrder',
      name: 'headOrder',
      component: () => import('../views/headCategory/headOrder.vue')
    },
  ],
  },
  {
      path: '*',
      redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]
console.log(process.env.BASE_URL)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
   // let token = sessionStorage.getItem('Authorization');
   let token = localStorage.getItem('Authorization');
    if (token === 'null' || token === null || token === '' || token === undefined) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
